@import '../node_modules/bootstrap/dist/css/bootstrap.css';


.bi  {
  height: 1.5rem;
  width: 1.5rem;
}

.bi-circle-fill {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml,<svg class='bi bi-x-circle-fill' viewBox='0 0 20 20' fill='%23dc3545' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM7.354 6.646L10 9.293l2.646-2.647a.5.5 0 01.708.708L10.707 10l2.647 2.646a.5.5 0 01-.708.708L10 10.707l-2.646 2.647a.5.5 0 01-.708-.708L9.293 10 6.646 7.354a.5.5 0 11.708-.708z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
}

.bi-document-text {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml,<svg class='bi bi-document-text' width='1em' height='1em' viewBox='0 0 20 20' fill='%2328a745' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M6 3h8a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2zm0 1a1 1 0 00-1 1v10a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1H6z' clip-rule='evenodd'/><path fill-rule='evenodd' d='M6.5 14a.5.5 0 01.5-.5h3a.5.5 0 010 1H7a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h6a.5.5 0 010 1H7a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h6a.5.5 0 010 1H7a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h6a.5.5 0 010 1H7a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h6a.5.5 0 010 1H7a.5.5 0 01-.5-.5z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
}

.bi-check-circle {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml,<svg class='bi bi-check-circle' width='1em' height='1em' viewBox='0 0 20 20' fill='%2328a745' xmlns='http://www.w3.org/2000/svg'>  <path fill-rule='evenodd' d='M17.354 4.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3-3a.5.5 0 11.708-.708L10 11.293l6.646-6.647a.5.5 0 01.708 0z' clip-rule='evenodd'/>  <path fill-rule='evenodd' d='M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63.5.5 0 11-.5.865A5.472 5.472 0 0010 4.5z' clip-rule='evenodd'/></svg>");
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v6/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

.container {
  padding: 50px 0 20px 0;
  width: 100%;
}

.quill {
  background-color: #FFF;
}

.ql-editor{
    height: 300px;
    text-align:left;
}

input, select {
  text-align: center;
  border: 5px solid;
  padding: 13px 12px;
  font-size: 16px;
  outline: none;
  margin-bottom: 20px;
}

select {
    width: 225px;
}

ul {
  margin-left: 20px;
  text-align: left;
}


html {
  background: url(bg.jpg) repeat-y center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


body {
  background: none;
}

h1 {
  font-size: 500%;
  font-family: 'Montserrat', sans-serif;
}

h2 {
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
}

h3 {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
}

.handyman h1, .handyman h2, .handyman h3 {
    text-align: center;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	/* border: 0; */
}

form {
    margin-top: 1em;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/*
 * Browser upgrade prompt
 * ========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/*
 * Print styles
 * Inlined to avoid the additional HTTP request:
 * http://www.phpied.com/delay-loading-your-print-css/
 * ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.Navigation-link {
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
  font-size: 1.125em; /* ~18px */
}

.Navigation-link,
.Navigation-link:active,
.Navigation-link:visited {
  color: rgba(0, 0, 0, .6);
}

.Navigation-link:hover {
  color: rgba(100, 100, 100, 1);
}

.Navigation-link--highlight {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 3px;
  background: rgba(0, 0, 0, .15);
  color: rgba(0, 0, 0, .6);
}

.Navigation-link--highlight:hover {
  background: rgba(0, 0, 0, .3);
}

.Navigation-spacer {
  color: rgba(100, 100, 100, .3);
}

.Navigation .active {
  background: rgba(0, 0, 0, .15);
  border-radius: 5px;
}

.Navigation {
    margin-bottom: 1em;
}

th {
    text-align: center;
}

h1 {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#emailEdit button {
    margin-top: 1em;
}

#apartmentEdit input {
  border: 1px solid #999;
  padding: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 0 0 0 0.4em;
  text-align: left;
}

#apartmentEdit input[type="checkbox"] {
  width: auto;
}

#header {
    text-align: center;
}

#content > div > div.login.center-block {
  width: 50%;
  margin: 0 auto;
}

#content > div > div.login.center-block > h2 {
  text-align: center;
}

.form-buttons {
  text-align: right;
}

#apartmentEdit, #apartmentsTable, #waitingList table {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, .8);
}

#waitingList table {
    margin-top: 1em;
}

#apartmentsTable > tbody > tr > td,
#waitingList table > tbody > tr > td {
    text-align: left;
}

#apartmentEdit td {
  vertical-align: middle;
}

#apartmentEdit label {
  font-weight: normal;
}

#emailEdit > button:nth-child(2) {
  margin-right: 1em;
}

#apartmentEdit button {
  width: 100%;
}

#content > div > div.login.center-block > hr {
    border: 0;
    height: 1px;
    margin: 2em 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

#waitingListApply {
    margin-bottom: 1em;
}

#waitingListApply h2 {
    text-align: center;
}

#waitingListApply form input {
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
    padding: 0 0 0 0.5em;
    font-size: 100%;
}

#waitingListApply label {
    font-weight: normal;
}

#waitingListApply .error {
    color: #F00;
}

.printError{
    color:red;
}
.login input {
    text-align: left;
}

.welcome div {

}

.welcome h2 {
    font-size: 18px;
    text-align: center;
    margin: 0 0 0.5em 0;
}

.settings, .welcome {
    border-radius: 5px;
    background-color: rgba(255,255,255,.8);
    width: 50%;
    padding: 8px;
}

#content > div > div.settings.center-block > form > div.form-group > label {
    padding-top: 5px;
}

.settingsInputs {
    width: 30%;
    margin-bottom: 10px;
    text-align: left;
}
.welcome {
    padding: 1em;
}


.displayNone {
    display: none;
}

#apartmentTableFunctions, #waitingListFunctions {
    text-align: center;
}

.generalAssembly {
    text-align: center;
}



.panel-transparent {
     background: rgba(255,255,255,.8);
}

.panelTransparent{
    background: rgba(255, 255, 255, 0.9);
}

.standardBackground{
    background: rgba(255,255,255,.9);
    padding-top:1em;
    padding-bottom: 1em;
    padding-left:1em;
    padding-right:1em;
}

.textAlignLeft{
    text-align:left;
}

.textAlignCenter{
    text-align:center;
}

.panelRowPadding{
    padding-bottom:0.5em;
}

.floatRight{
    float:right;
}
.floatLeft{
    float:left;
}

.tableFixed{
    table-layout: fixed;
}
.center {
    text-align: center;
    vertical-align: middle;
}

.midButtonWithPadding {
    padding-bottom:2em;
    text-align: center;
}

.makeGreen{
    color:green;
}

.makeRed{
    color:red;
}

.color{
    background-color: rgba(255,255,255,.8);
}

.clickMobile{
    cursor:'pointer';
}
@media only screen and (max-width: 1080px) {
  html {
    background: url(bg.jpg);
    background-repeat: repeat;
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;
    background-size: auto;
  }
}

#loading {
    font-style: italic;
}

.handymanList input, .boardMemberList input {
  text-align: left;
}

.handymanList input, .handymanList panel, .boardMemberList input {
    width: 100%;
}

.handyman {
  margin-top: 1em;
  padding-left: 0;
  padding-right: 0;
}

.slightMarginTopApartments{
    margin-top:2em;
}

.boardMemberName {
  font-weight: bold;
}

.menuBarMargin{
    margin-top:-4.5em;
}


@media all and (max-width: 376px) {
    .welcomeHeader {
        font: bold 3em Helvetica, Sans-Serif;
        letter-spacing: -1px;
        padding-right: 10px;
        padding-top: 1.5em;
        padding-bottom: 0.5em;

    }

    .welcomeHeaderDiv{
        margin-top:-4em;
    }
}

.handymanList tr {
  border-bottom: 2px #DDD solid;
}

.marginLeft1{
  margin-left:-1em;
}

.marginBottom1{
  margin-bottom:1em;
}
